<div class="form-group">
  <label *ngIf="label" [for]="'field-component-' + formControlName + (controlContainer?.name ? '_' + controlContainer.name : '')">{{ label }}<span *ngIf="required"> *</span></label>
  <div>
    <input
      class="form-control form-control-sm typeahead-with-icon-search"
      type="text"
      container="body"
      autocomplete="off"
      [formControl]="typeaheadControl"
      [id]="'field-component-' + formControlName + (controlContainer?.name ? '_' + controlContainer.name : '')"
      [required]="required"
      [readonly]="readonly"
      [typeahead]="dataSource"
      [typeaheadOptionsLimit]="optionsLimit"
      [typeaheadMinLength]="minLength"
      [typeaheadLatinize]="true"
      [typeaheadSelectFirstItem]="selectFirstItem"
      [typeaheadIsFirstItemActive]="isFirstItemActive"
      [typeaheadOptionField]="labelProp"
      [adaptivePosition]="true"
      [class.icon-padding]="icon"
      (typeaheadOnSelect)="selectItem($event)"
      (typeaheadNoResults)="typeaheadNoResult($event)"
      (blur)="onBlur()"
    >
    <fa-icon *ngIf="icon" [icon]="['fas', icon]" [fixedWidth]="true"></fa-icon>
  </div>
  <validation-errors *ngIf="control?.invalid && (control?.dirty || control?.touched)" [errors]="control?.errors" [label]="label" class="invalid-feedback"></validation-errors>
  <small *ngIf="description" class="form-text text-muted">{{ description }}</small>
</div>
